<template>
  <div id="app">
    <nav class="navbar navbar-expand navbar-light bg-light">
      <NavBarMainMenuView v-if="this.loggedIn"/>
      <NavBarLoggedOutView v-if="!this.loggedIn"/>
    </nav>
    <div class="container">
      <router-view />
    </div>
  </div>
</template>
<script>
import NavBarMainMenuView from './components/Navbar/NavBarMainMenuView.vue';
import NavBarLoggedOutView from './components/Navbar/NavBarLoggedOutView.vue'

export default {
  components: {NavBarMainMenuView, NavBarLoggedOutView},
  computed: {
    loggedIn(){
      return this.$store.state.auth.user && localStorage.getItem('user');
    },
    windowWidth() {
      return this.$store.state.resizer.windowWidth;
    },
    basedOnWindowWidth() {
      if (this.windowWidth > 768) {
        return true;
      } return false;
    },
  },
  mounted() {
    window.addEventListener(
      'resize',
      this.resizeMethod)
  },
  methods: {
    resizeMethod(){
      this.$store.commit('resizer/setWindowWidth')
    }
  }
};
</script>
<style>

@font-face {
  font-family: 'Roboto-Slab';
  src: url("assets/RobotoSlab-VariableFont_wght.ttf") format("truetype");
}

/* Minimum width */
@media (min-width: 1000px) {
  body  {
  background-image: url("assets/background-4.png");
  /* width: 500rem; */
  /* height: 300rem; */
  font-family: 'Roboto-Slab';
  }
}

/* Minimum width */
@media (400px < width < 1000px) {
  body  {
  background-image: url("assets/background-4.png");
  /* width: 500rem; */
  /* height: 300rem; */
  font-family: 'Roboto-Slab';
  }
}

/* Maximum width */
@media (max-width: 400px) {
  body  {
  background-image: url("assets/background-4.png");
  /* width: 500rem; */
  /* height: 300rem; */
  font-family: 'Roboto-Slab';
  }
}

.app-h1{
  text-align: center;
  background: rgba(13, 13, 65, 0.9);
  border-radius: 0.25rem;
  color: #f0f0f0;
}

#home-link{
  display: flex;
  font-size: 2rem;
  font-family: 'Roboto-Slab';
  font-style: italic;
}

.bt-icon{
    background: url('./assets/logo-icon.png');
    height: 2rem;
    width: 2rem;
    display: block;
    border-radius: 1rem;
    margin-top:0.5rem;
}

  .container{
    margin-top:5rem;
    font-family: 'Roboto-Slab';
    font-size:large
  }
  .nav-link{
    cursor: pointer;
    display: inline;
  }
  .bt-footer{
  position: fixed;
  padding: 10px 10px 0px 10px;
  bottom: 0;
  width: 100%;
  height: 40px;
  background: #4F5D75;
}
.bt-file-upload{
  border: 1px solid #ccc;
  display: inline-block;
  cursor: pointer;
}
.bt-file-label {
  background-color: midnightblue;
  color: #f0f0f0;
  padding: 1rem;
  font-family: sans-serif;
  border-radius: 0.3rem;
  cursor: pointer;
  margin-top: 1rem;
  border-radius: 1rem;
  box-shadow: 0.3rem 0.4rem 0.4rem #3633338c;
  font-family: "Roboto Slab";
  font-size:larger
}
.bt-file-label:hover {
  background-color: rgb(6, 6, 75);
  box-shadow: 0.4rem 0.5rem 0.5rem #201f1f8c;
}
.bt-file-label:active {
  background-color: rgb(13, 4, 53);
  box-shadow: 0.6rem 0.6rem 0.6rem #0808088c;
}
.bt-input{
  font-family:"Roboto Slab";
  padding-left: 1rem;
  padding-right:1rem;
  margin-right:1rem;
  border-radius: 1rem;
  font-size: large;
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(30, 33, 213, 0.9);
}

.bt-input:hover{
  background-color: rgb(65, 65, 114);
  color:white
}

.bt-input:active{
  background-color: midnightblue;
  color:white
}

.nav-item{
  cursor: pointer;
}

.navbar{
  position: fixed !important; /* Set the navbar to fixed position */
  top: 0; /* Position the navbar at the top of the page */
  width: 100%;
  white-space: nowrap;
  overflow: auto;
}

.router-link-active{
  background-color: rgba(0,0,0,0.1);
}

.navbar a{
  margin-left:0.5rem;
  border-radius:0.5rem;
  box-shadow: 0.05rem 0.05rem 0.05rem rgba(0,0,0,0.1);
  font-family: 'Roboto-Slab';
  font-size: x-large;
}
.navbar a:hover{
  border-radius: 0.5rem;
  box-shadow: 0.1rem 0.2rem 0.3rem #888888;
}
.navbar a:active{
  border-radius: 0.5rem;
  box-shadow: 0.3rem 0.4rem 0.4rem #3633338c;
}

table{
  width:100%;
  text-align: center;
  border-radius: 1rem;
  box-shadow: 0.25rem 0.25rem 0.25rem #302d3b8c;
  line-height: 2;
  font-size:larger
}

th{
  height: 5rem;
  font-size: 1.5rem;
}

td{
  padding-right:1rem;
}


tr:nth-child(odd){
  background-color: rgba(255, 255, 255, 0.9);
}

tr:nth-child(even),th 
{background-color: rgba(223, 223, 223, 0.95);} 

td:hover {
  color:white;
  cursor: pointer;
  background-color: rgb(49, 29, 95);
} 

.home-icon{
  color:blue
}

.admin-icon{
  color:green
}

.members-icon{
  color:blue
}

.doc-icon{
  color: red;
}

.home-icon:hover{
  color:rgba(0, 0, 255, 0.4)
}

.admin-icon:hover{
  color:rgba(0, 128, 0, 0.4)
}

.members-icon:hover{
  color:rgba(0, 0, 255, 0.4)
}

.doc-icon:hover{
  color: rgba(255, 0, 0, 0.4);
}

.align-icon{
  color: rgba(0,255,0, 0.8);
}

.align-icon:hover{
  color: rgba(0, 255, 0, 0.4);
}

</style>