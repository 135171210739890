import axios from 'axios';
import authHeader from './auth-header';

const EVENTS_API_URL = 'https://broxtech-backend-ced04e937784.herokuapp.com/participants';

class EventService {
  async delete(participant){
    await axios.delete(EVENTS_API_URL + '/delete/' + participant, { headers: authHeader() });
  }
  getEventParticipants() {
    return axios.get(EVENTS_API_URL, { headers: authHeader() });
  }
  signUpForEvent(participant){
    return axios.post(EVENTS_API_URL, {
      names: participant.names,
      email: participant.email,
      age: participant.age? participant.age: "",
      address: participant.address? participant.address: "",
      postcode: participant.postcode? participant.postcode: "",
      phone: participant.phone? participant.phone: "",
      diet: participant.diet? participant.diet: "",
      inform: participant.inform? participant.inform: "",
      photo_consent: participant.photo_consent? false: true
    })
  }
}
export default new EventService();
