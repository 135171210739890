import SubscriptionService from '../services/subscription.service';

const initialState = {
    subscriptions: [],
    subscription: null,
    subsriber: false
}
export const subscriptions = {
    namespaced: true,
    state: initialState,
    actions: {
        async getSubscriptions({ commit }) {
            let allSubscriptions = await SubscriptionService.getSubscriptions();
            commit('populateSubscriptions', allSubscriptions.data.subscriptions);
          },
          async signUpUserForSubscription({commit}, subscription){
            await SubscriptionService.signUpForSubscription(subscription)
            commit('registerSubscription')
          },
    },
    mutations: {
        registerSubscription(state) {
          state.subsriber = true;
        },
        populateSubscriptions(state, subscriptions){
          state.subscriptions = subscriptions
        }
    },
}