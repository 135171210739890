import { createStore } from "vuex";
import { auth } from "./auth.module";
import { user } from "./user.module"
import { role } from './role.module';
import { group } from './groups.module';
import { documents } from './documents.module';
import { resizer } from "./resize.module";
import { events } from "./events.module"
import { organisations} from './organisations.module'
import { subscriptions } from "./subscriptions.module";


const store = createStore({
  modules: {
    auth,
    user,
    role,
    group,
    documents,
    resizer,
    events,
    organisations,
    subscriptions
  },
});
export default store;
