<template>
    <div class="navbar-nav mr-auto">
        <li class="nav-item">
            <router-link to="/home" class="nav-link">
                <font-awesome-icon icon="home" class="home-icon" />
            </router-link>
        </li>
        <!-- <li class="nav-item">
            <router-link to="/enquiry" class="nav-link">
                <font-awesome-icon icon="contact-card" class="members-icon" />
                <span v-if="this.windowWidth>415">Contact</span>
            </router-link>
        </li>
        <li class="nav-item">
            <router-link to="/products" class="nav-link">
                <font-awesome-icon icon="basket-shopping" class="members-icon" />
                <span v-if="this.windowWidth>415">Products</span>
            </router-link>
        </li> -->
        <div>
            <li class="nav-item">
                <router-link to="/login" class="nav-link">
                    <font-awesome-icon icon="sign-in-alt" class="members-icon" />
                    <span v-if="this.windowWidth>415">Members</span>
                </router-link>
            </li>
            <!-- <li class="nav-item">
                <router-link to="/register" class="nav-link">
                    <font-awesome-icon icon="sign-in-alt" class="members-icon" />
                    <span v-if="this.windowWidth>400">Sign Up</span>
                </router-link>
            </li> -->
        </div>
    </div>
</template>

<script>
export default {
    name: 'NavBarLoggedOutView',
    computed: {
        windowWidth() {
            return this.$store.state.resizer.windowWidth;
        },
        basedOnWindowWidth() {
            if (this.windowWidth > 768) {
                return true;
            } return false;
        }
    },
    mounted() {
        window.addEventListener(
            'resize',
            this.resizeMethod)
    },
    methods: {
        resizeMethod() {
            this.$store.commit('resizer/setWindowWidth')
        }
    }
};
</script>

<style>
.products {
    list-style-type: none;
    /* Remove bullets */
    font-family: "Roboto Slab";
    font-size: x-large;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 1rem;
    border-radius: 1rem;
    box-shadow: 0.3rem 0.4rem 0.4rem #3633338c;
}

.products:hover {
    background-color: rgb(250, 250, 250, 0.95);
    box-shadow: 0.4rem 0.6rem 0.6rem #1816168c;
}
</style>