<template>
  <div class="home">
    <div class="brox-headers">
      <h1 class="app-h1">Broxtech</h1>
      <img class="brox-img" src="../../assets/logo.png"/>
      <h2>Welcome</h2>
    </div>
    <div class="main-content">
      <p>Broxtech provides a flexible set of tools for small businesses</p>
      <p>We do:</p>
      <ul>
        <li>Automation</li>
        <li>Document Management</li>
        <li>Personnel Management</li>
        <li>Web Development</li>
        <li>Contract and Consultancy</li>
      </ul>      
      <router-link to="/register" class="nav-link">
        <p>Interested? Begin your journey by creating a free account</p>
      </router-link>
      <router-link to="/products" class="nav-link">
        <p>Ready? Choose a product and subscription that suits you</p>
      </router-link>
    </div>
    <div class="contact">
      <router-link to="/enquiry" class="nav-link">
        Get in Touch
      </router-link>
      <font-awesome-icon icon="phone" /> 555-4444
    </div>
    <div class="image-footer">
      <!-- <img :class="windowWidth>768?'brox-img-wide':'brox-img-small'" src="../../assets/logo.png"/> -->
    </div>
  </div>
</template>
<script>

export default {
  name: "HomeView",
  computed:{
    windowWidth() {
      return this.$store.state.resizer.windowWidth;
    },
  },
  data() {
    return {
      content: "Welcome to Broxtech",
    };
  },
  mounted() {
    
  },
};
</script>

<style scoped>

.home{
    font-family: "Roboto Slab";
    font-size: x-large;
    background-color: rgba(255,255,255, 0.9);
    padding:1rem;
    border-radius: 1rem;
    box-shadow: 0.3rem 0.4rem 0.4rem #3633338c;
}

.home:hover{
  background-color: rgb(250, 250, 250, 0.95);
  box-shadow: 0.4rem 0.6rem 0.6rem #1816168c;
}

img{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 10%;
}
.image-footer-wide{
  /* bring your own prefixes */
  border: 1rem solid black;
  /* box-shadow: 0.1rem 0.1rem rgba(255,255,255,0.5); */
  border-radius: 1rem;
  width:50rem;
}

.brox-img-wide{
  width:80%;
  border: 1rem solid black;
  border-radius: 1rem;
}

.brox-img-small{
  width:80%;
  border: 1rem solid black;
  border-radius: 1rem;
}

.brox-img-wide:hover{
  cursor: pointer;
  background-color: rgba(255,255,255,0.1);
}

.brox-img-small:hover{
  cursor: pointer;
  background-color: rgba(255,255,255,0.1);
}

</style>
