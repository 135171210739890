<template>
  <div class="about">
    <h1 class=app-h1>About</h1>
 </div>
</template>

<script>
export default {
  name: 'AboutView'
};
</script>

<style>
.about{
    font-family: "Roboto Slab";
    font-size: x-large;
    background-color: rgba(255,255,255, 0.9);
    padding:1rem;
    border-radius: 1rem;
    box-shadow: 0.3rem 0.4rem 0.4rem #3633338c;
}

.about:hover{
  background-color: rgb(250, 250, 250, 0.95);
  box-shadow: 0.4rem 0.6rem 0.6rem #1816168c;
}
</style>