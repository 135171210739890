import axios from 'axios';
import authHeader from './auth-header';

const ORGANISATIONS_API_URL = 'https://broxtech-backend-ced04e937784.herokuapp.com/organisations';

class OrganisationService {
  async delete(organisation){
    await axios.delete(ORGANISATIONS_API_URL + '/delete/' + organisation, { headers: authHeader() });
  }
  async removeUser(email){
    await axios.delete(ORGANISATIONS_API_URL + '/users/delete/' + email, { headers: authHeader() });
  }
  async getOrganisations() {
    return await axios.get(ORGANISATIONS_API_URL, { headers: authHeader() });
  }
  async getOrganisation(uri){
    return await axios.get(ORGANISATIONS_API_URL + '/' + uri, {headers: authHeader() });
  }
  async getOrganisationUsers(uri){
    return await axios.get(ORGANISATIONS_API_URL + '/' + uri + '/users', {headers: authHeader() });
  }
  async addOrganisation(organisation){
    var formData = new FormData()
    formData.append('name', organisation.name)
    formData.append('uri', organisation.uri)
    formData.append('lead_user_email', organisation.lead_user_email)
    return await axios.post(ORGANISATIONS_API_URL, formData, { headers: authHeader() })
  }
  async addUserToOrganisation(user){
    var formData = new FormData()
    formData.append('email', user.email)
    formData.append('uri', user.uri)
    formData.append('role', user.role)
    return await axios.post(ORGANISATIONS_API_URL + '/' + user.uri + '/users', formData, { headers: authHeader() })
  }
}
export default new OrganisationService();
