import GroupService from '../services/group.service';

const initialState = {
    groups: [],
}
export const group = {
    namespaced: true,
    state: initialState,
    actions: {
        async getGroups({ commit }) {
            let allGroups = await GroupService.getGroups();
            commit('populateGroups', allGroups.data.groups);
          }
    },
    mutations: {
        populateGroups(state, groups) {
            state.groups = groups;
          }
    },
}