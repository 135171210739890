import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faHome,
  faContactCard,
  faFileContract,
  faUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
  faPhone,
  faTrash,
  faFile,
  faCircleArrowDown,
  faSpinner,
  faBasketShopping,
  faCartShopping,
  faBars
} from "@fortawesome/free-solid-svg-icons";
library.add(
  faHome, 
  faContactCard,
  faFileContract,
  faUser, 
  faUserPlus, 
  faSignInAlt, 
  faSignOutAlt, 
  faPhone, 
  faTrash, 
  faFile, 
  faCircleArrowDown, 
  faSpinner, 
  faBasketShopping, 
  faCartShopping,
  faBars);
export { FontAwesomeIcon };
