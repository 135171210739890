import EventService from '../services/event.service';

const initialState = {
    participants: [],
    participant: null,
    registered: false
}
export const events = {
    namespaced: true,
    state: initialState,
    actions: {
        async getParticipants({ commit }) {
            let allParticipants = await EventService.getEventParticipants();
            commit('populateParticipants', allParticipants.data.participants);
          },
          async signUpParticipantForEvent({commit}, participant){
            await EventService.signUpForEvent(participant)
            commit('registerParticipant')
          },
    },
    mutations: {
        registerParticipant(state) {
          state.registered = true;
        },
        populateParticipants(state, participants){
          state.participants = participants
        }
    },
}