import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'https://broxtech-backend-ced04e937784.herokuapp.com/subscriptions';

class SubscriptionService {
  signUpForSubscription(subscription){
    return axios.post(API_URL, {
      user: subscription.user,
      product: subscription.product,
      subscription_length: subscription.subscription_length
    })
  }
  editSubscription(subscription){
    return axios.put(API_URL, {
      id: subscription.id,
      user: subscription.user,
      product: subscription.product,
      subscription_length: subscription.subscription_length
    })
  }
  getSubscriptions() {
    return axios.get(API_URL, { headers: authHeader() });
  }
  getSubscription() {
    return axios.get(API_URL, { headers: authHeader() });
  }
  async delete(subscriber_id){
    await axios.delete(API_URL + '/delete/' + subscriber_id, { headers: authHeader() });
  }
}
export default new SubscriptionService();
